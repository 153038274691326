var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"visible":_vm.visible,"title":_vm.dialogTitle,"close-on-click-modal":false,"width":"700px"},on:{"close":_vm.handleCancel}},[_c('el-form',{attrs:{"id":"item","label-width":"90px","label-position":"right","label-suffix":"：","inline":false},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{attrs:{"label":"货号"}},[_c('el-autocomplete',{ref:"queryItemNo",staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入货号","trigger-on-focus":false,"value-key":"ItemNo","fetch-suggestions":_vm.querySearchItemNo,"disabled":!_vm.isAdd},on:{"change":function($event){return _vm.handleMatchItemNo()},"select":function($event){return _vm.handleSeletedItemNo($event)}},model:{value:(_vm.form.ItemNo),callback:function ($$v) {_vm.$set(_vm.form, "ItemNo", $$v)},expression:"form.ItemNo"}})],1),_c('el-form-item',{attrs:{"label":"商品价"}},[_vm._v(" ￥"+_vm._s(_vm.form.Price)+" ")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.form.Details,"cell-class-name":_vm.handleCellClassName,"border":"","row-class-name":_vm.handleRowClassName}},[_c('el-table-column',{attrs:{"prop":"Color","label":"颜色","width":"125","show-overflow-tooltip":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row._IsAdd)?[_c('el-select',{staticStyle:{"width":"95px"},model:{value:(row.Color),callback:function ($$v) {_vm.$set(row, "Color", $$v)},expression:"row.Color"}},[_c('el-option',{attrs:{"label":"全部","value":"全部"}}),_vm._l((_vm.form.ColorList),function(item,index){return _c('el-option',{key:'color_' + index,attrs:{"label":item,"value":item}})})],2)]:[_vm._v(" "+_vm._s(row.Color)+" ")]]}}])}),_c('el-table-column',{attrs:{"prop":"Size","label":"尺码","width":"125","show-overflow-tooltip":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row._IsAdd)?[_c('el-select',{staticStyle:{"width":"95px"},model:{value:(row.Size),callback:function ($$v) {_vm.$set(row, "Size", $$v)},expression:"row.Size"}},[_c('el-option',{attrs:{"label":"全部","value":"全部"}}),_vm._l((_vm.form.SizeList),function(item,index){return _c('el-option',{key:'size_' + index,attrs:{"label":item,"value":item}})})],2)]:[_vm._v(" "+_vm._s(row.Size)+" ")]]}}])}),_c('el-table-column',{attrs:{"prop":"Price","label":"商品价","width":"110","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:row.IsDelete ? 'span_deleted' : ''},[_vm._v(_vm._s(row.Price))])]}}])}),_c('el-table-column',{attrs:{"prop":"SettlePrice","label":"指定价","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{staticStyle:{"width":"100px"},on:{"change":_vm.handleChanged},model:{value:(row.SettleType),callback:function ($$v) {_vm.$set(row, "SettleType", $$v)},expression:"row.SettleType"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-input-number',{staticStyle:{"width":"70px","margin-left":"3px"},attrs:{"min":0,"controls":false,"precision":2},on:{"focus":function($event){return _vm.handleSelectText($event)},"change":_vm.handleChanged},model:{value:(row.SettlePrice),callback:function ($$v) {_vm.$set(row, "SettlePrice", $$v)},expression:"row.SettlePrice"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row._IsAdd)?[_c('el-button',{staticStyle:{"color":"green"},attrs:{"type":"text","icon":"el-icon-circle-plus-outline"},on:{"click":function($event){return _vm.addItem(row)}}},[_vm._v("新增")])]:[_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","icon":"el-icon-delete"},on:{"click":function($event){return _vm.delItem(row, $index)}}},[_vm._v("删除")])]]}}])})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":_vm.handleSave}},[_vm._v("确 定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }