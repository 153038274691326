<template>
    <el-dialog :visible="visible" :title="dialogTitle" @close="handleCancel" :close-on-click-modal="false" width="700px">
        <el-form id="item" label-width="90px" label-position="right" label-suffix="：" :inline="false" @submit.native.prevent>
            <el-form-item label="货号">
                <el-autocomplete placeholder="请输入货号" ref="queryItemNo" v-model="form.ItemNo" :trigger-on-focus="false"
                    value-key="ItemNo" :fetch-suggestions="querySearchItemNo" @change="handleMatchItemNo()"
                    @select="handleSeletedItemNo($event)" :disabled="!isAdd" style="width:200px"></el-autocomplete>
            </el-form-item>
            <el-form-item label="商品价">
                ￥{{ form.Price }}
            </el-form-item>
        </el-form>
        <el-table :data="form.Details" :cell-class-name="handleCellClassName" border :row-class-name="handleRowClassName"
            v-loading="loading">
            <el-table-column prop="Color" label="颜色" width="125" show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    <template v-if="row._IsAdd">
                        <el-select v-model="row.Color" style="width: 95px;">
                            <el-option label="全部" value="全部"></el-option>
                            <el-option v-for="(item, index) in form.ColorList" :key="'color_' + index" :label="item"
                                :value="item"></el-option>
                        </el-select>
                    </template>
                    <template v-else>
                        {{ row.Color }}
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="Size" label="尺码" width="125" show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    <template v-if="row._IsAdd">
                        <el-select v-model="row.Size" style="width: 95px;">
                            <el-option label="全部" value="全部"></el-option>
                            <el-option v-for="(item, index) in form.SizeList" :key="'size_' + index" :label="item"
                                :value="item"></el-option>
                        </el-select>
                    </template>
                    <template v-else>
                        {{ row.Size }}
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="Price" label="商品价" width="110" align="center">
                <template slot-scope="{ row }">
                    <span :class="row.IsDelete ? 'span_deleted' : ''">{{ row.Price }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="SettlePrice" label="指定价" align="center">
                <template slot-scope="{ row }">
                    <el-select v-model="row.SettleType" style="width: 100px;" @change="handleChanged">
                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                    <el-input-number v-model="row.SettlePrice" :min="0" :controls="false" :precision="2"
                        @focus="handleSelectText($event)" @change="handleChanged" style="width: 70px;margin-left:3px;" />
                </template>
            </el-table-column>
            <el-table-column label="操作" width="80" align="center">
                <template slot-scope="{ row, $index }">
                    <template v-if="row._IsAdd">
                        <el-button type="text" icon="el-icon-circle-plus-outline" style="color:green;"
                            @click="addItem(row)">新增</el-button>
                    </template>
                    <template v-else>
                        <el-button type="text" icon="el-icon-delete" style="color:red;"
                            @click="delItem(row, $index)">删除</el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取 消</el-button>
            <el-button type="primary" @click="handleSave" :loading="saveLoading">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
export default {
    name: 'SettlePriceEdit',
    data() {
        return {
            isAdd: true,
            loading: false,
            saveLoading: false,
            options: [
                { value: 0, label: '固定价' },
                { value: 1, label: '便宜' },
            ],
            form: {
                Purchaser: null,
                Product: null,//选中项
                ColorList: [],
                SizeList: [],
                ItemNo: '',
                Price: 0,
                Details: [],
                IsUpdate: false,
            },
            visible: false,
            dialogTitle: '编辑',
            searchItems: [],
            timeout: null,
        };
    },
    methods: {
        handleSelectText(e) {
            e.currentTarget.select();
        },
        handleRowClassName({ row }) {
            if (row.IsDelete) {
                return 'is-deleted';
            }
            return "";
        },
        handleCellClassName({ row, column }) {
            if (["Color", "Size"].includes(column.property)) {
                return "mark";
            }
            return "";
        },
        handleChanged() {
            this.form.IsUpdate = true;
        },
        async delItem(row, idx) {
            this.form.Details.splice(idx, 1);
            this.form.IsUpdate = true;
        },
        async addItem(row) {
            var that = this;
            if (typeof (row.Color) == 'undefined' || row.Color == '') {
                that.$message({ type: "error", message: '请选择颜色' }); return;
            }
            if (typeof (row.Size) == 'undefined' || row.Size == '') {
                that.$message({ type: "error", message: '请选择尺码' }); return;
            }
            var hasSpec = that.form.Details.some(t => !t._IsAdd && t.Color == row.Color && t.Size == row.Size);
            if (hasSpec) {
                that.$message({ type: "error", message: '颜色【' + row.Color + '】尺码【' + row.Size + '】已存在' }); return;
            }
            var item = { ...row };
            item._IsAdd = undefined;
            item.ProductID = that.form.Product.ProductID
            if (item.Color != '全部' && item.Size != '全部') {
                var specs = that.form.Product.Specs.filter(x => x.Color == item.Color && x.Size == row.Size);
                if (specs.length > 0) {
                    item.SpecID = specs[0].SpecID;
                    item.Price = specs[0].Price;
                }
            }
            that.form.Details.push(item);
            that.addDetailsAndSort(that.form.Details.filter(x => !x._IsAdd));
            that.form.IsUpdate = true;
        },
        async open(purchaser, itemNo) {
            var that = this;
            this.visible = true;
            if (typeof (itemNo) != 'undefined' && itemNo != '') {
                that.form.ItemNo = itemNo;
            }
            that.form.Purchaser = purchaser;
            that.isAdd = that.form.ItemNo == '';
            that.dialogTitle = (that.isAdd ? '新增' : '编辑') + '特殊定价';
            if (!that.isAdd) {
                var products = await that.getProducts(itemNo, false);
                await that.handleSeletedItemNo(products[0]);
            }
        },
        async handleSeletedItemNo(selectedRow) {
            var that = this;
            that.loading = true;
            that.form.Product = JSON.parse(JSON.stringify(selectedRow));
            that.form.Details = [];
            that.form.Price = that.form.Product.Price;
            if (that.form.Product.Specs.length > 0) {
                let colorObj = {};
                let sizeObj = {};
                for (var i = 0; i < that.form.Product.Specs.length; i++) {
                    colorObj[that.form.Product.Specs[i].Color] = 1;
                    sizeObj[that.form.Product.Specs[i].Size] = 1;
                }
                that.form.ColorList = Object.keys(colorObj);
                that.form.SizeList = Object.keys(sizeObj);
                const { data } = await submit("/api/stall/getPriceDetails", { PurchaserID: that.form.Purchaser.PurchaserID, ProductID: that.form.Product.ProductID });
                that.addDetailsAndSort(data ?? []);
            }
            else {
                that.addEmptyRow();
            }
            that.loading = false;
        },
        addDetailsAndSort(data) {
            var that = this;
            data = data.sort(function (a, b) {
                if (a.Color !== b.Color) return a.Color < b.Color ? -1 : 1;
                else if (a.Size !== b.Size) return a.Size < b.Size ? -1 : 1
            });
            that.form.Details = data;
            that.addEmptyRow();
        },
        addEmptyRow() {
            this.form.Details.push({
                _IsAdd: true,
                ProductID: 0,
                SpecID: 0,
                Color: undefined,
                Size: undefined,
                Price: this.form.Price,
                SettleType: 0,
                SettlePrice: 0,
            });
        },
        handleMatchItemNo() {
            var that = this;
            let selectedRow = that.searchItems.filter((i) => i.ItemNo.toLowerCase() == that.form.ItemNo.toLowerCase());
            if (selectedRow.length > 0) {
                that.handleSeletedItemNo(selectedRow[0]);
            } else {
                that.form.Product = null;
                that.form.ColorList = [];
                that.form.SizeList = [];
                that.form.Details = [];
                that.form.Price = 0;
            }
        },
        async getProducts(keyword, search) {
            const { data } = await submit("/api/purchase/getProducts", {
                Keyword: keyword, GetSpecs: true, GetStock: false, FuzzySearch: search
            });
            return data;
        },
        async querySearchItemNo(query, callback) {
            var that = this;
            if (query !== '') {
                clearTimeout(that.timeout);
                that.timeout = setTimeout(async () => {//加上延时，避免段时间输入太多而请求太多次
                    that.searchItems = await that.getProducts(query, true);
                    if (callback != null) {
                        callback(that.searchItems);
                    }
                }, 200);
            }
        },
        handleClearData() {
            this.visible = false;
            this.form = {
                Purchaser: null,
                Product: null,//选中项
                ColorList: [],
                SizeList: [],
                ItemNo: '',
                Price: 0,
                Details: [],
                IsUpdate: false,
            };
        },
        handleCancel() {
            var that = this;
            if (that.form.IsUpdate) {
                that.$confirm(`当前存在数据变动未保存，是否继续退出？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    that.handleClearData();
                }).catch(() => {
                    //取消
                });
            } else {
                that.handleClearData();
            }
        },
        handleSave() {
            var that = this;
            var details = that.form.Details.filter(x => !x._IsAdd);
            if (details.length > 0) {
                for (let i = 0; i < details.length; i++) {
                    let item = details[i];
                    if (item.SettleType == 1 && item.SettlePrice > that.form.Product.Price) {
                        that.$message({ type: "error", message: '颜色【' + item.Color + '】尺码【' + item.Size + '】便宜数额不能超过商品单价' });
                        return;
                    }
                }
            }
            if (that.isAdd) {
                if (details.length <= 0) {
                    that.$message({ type: "error", message: '请选择要添加的数据' });
                    return;
                }
                that.doSave(details);
            } else {
                if (details.length <= 0) {
                    that.$confirm('当前编辑项列表为空，继续提交将清空采购商【' + that.form.Purchaser.Name + '】货号【' + that.form.Product.ItemNo + '】的所有指定价，确认提交？', "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        that.doSave(details);
                    }).catch(() => {
                        //取消
                    });
                } else {
                    that.doSave(details);
                }
            }
        },
        async doSave(details) {
            var that = this;
            that.saveLoading = true;
            let data = await submit('/api/stall/savePriceDetails', {
                PurchaserID: that.form.Purchaser.PurchaserID,
                ProductID: that.form.Product.ProductID,
                Details: details
            }).catch((error) => {
                that.saveLoading = false;
            });
            that.$message({ type: data.success ? 'success' : 'error', message: data.msg });
            that.saveLoading = false;
            that.handleClearData();
            that.visible = false;
            that.$emit('handleCallback');
        }
    }
}
</script>

<style  lang="scss" scoped>
::v-deep {
    .el-radio-button {
        width: 25%;

        .el-radio-button__inner {
            width: 100%;
        }
    }

    .el-form-item__error {
        display: contents;
    }

    .el-table__row {
        font-size: 14px;

        .mark {
            &>div {
                color: #409EFF !important;
            }
        }

        &.is-deleted {
            color: red;
            text-decoration: line-through;

            .cell {
                color: #b5b5b5;
            }
        }

        .el-table__cell {
            padding: 5px 0;
            vertical-align: middle;
        }
    }

    .el-tag {
        font-size: 14px;
        min-height: 30px;
        height: auto;
        line-height: 30px;
        margin: 2px !important;

        vertical-align: middle;
    }

    .el-select {
        width: 120px;
    }
}

.input-new-name {
    width: 100px;

    ::v-deep {
        .el-input__inner {
            height: 35px !important;
            padding-left: 5px;
        }
    }
}

.button-new-name {
    line-height: 35px;
    padding-top: 0;
    padding-bottom: 0;
}
</style>